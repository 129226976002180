<template>
  <span>
    <div class="row">
      <div class="col-12">
        <TableComponent :fields="fields" :items="messages" :busy="fetching">
          <template #cell(message)="slotProps">
            <router-link :to="`/whatsapp/jv/history/${slotProps.item.id}`"
               class="text-primary link-out-side"><span>Ver Mensagem</span>
              <span class="material-symbols-outlined link-message">arrow_outward</span>
            </router-link>
          </template>
          <template #cell(status)="slotProps">
            <BadgeComponent
              :variant="slotProps.data === 'SENT' ? 'info' : (slotProps.data === 'FAILED' ? 'danger' : 'success')">
              {{
                $tc(`app.${slotProps.data.toLowerCase()}`, 1)
              }}
            </BadgeComponent>
          </template>
        </TableComponent>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <PaginationComponent v-model="currentPage" :total-pages="totalPages"
                             @change="fetch"></PaginationComponent>
      </div>
    </div>
  </span>
</template>

<script>
// @ is an alias to /src
import WhatsService from '@/services/whatsapp.service';
import CustomerService from '@/services/customer.service';
import axios from '@/plugins/axios';
import moment from 'moment-timezone';
import TableComponent from '@/components/TableComponent.vue';
import BadgeComponent from '@/components/ui/BadgeComponent.vue';
import PaginationComponent from '@/components/PaginationComponent.vue';

const webApi = axios();

export default {
  name: 'WhatsappHistory',
  components: {
    PaginationComponent,
    BadgeComponent,
    TableComponent,
  },
  props: {
    filters: {
      type: Object,
    },
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },
  },
  data() {
    return {
      fetching: false,
      loading: true,
      fields: [
        { key: 'date', formatter: (value) => moment(value).format('DD/MM/YYYY HH:mm:ss') },
        'type',
        'sender',
        'recipient',
        'message',
        'status',
      ],
      messages: [],
      form: {
        status: null,
        sender: null,
        recipient: null,
        date_begin: null,
        date_end: null,
      },
      pages: 1,
      currentPage: 1,
      totalPages: 1,
      exporting: false,
    };
  },
  created() {
    this.fetch(1);
  },
  methods: {
    fetchCustomers() {
      CustomerService.getCustomers().then(
        (customers) => {
          this.customers = customers;
          this.customers.push(this.$store.state.account);
        },
        (error) => {
          this.content = error;
        },
      );
    },
    fetch(page) {
      this.currentPage = page;
      this.fetching = true;

      this.filters.page = page - 1;
      const form = this.form;

      const params = [];

      if (this.$route.params.id) {
        params.push(`campaign_ext_id;eq;'${encodeURIComponent(this.$route.params.id)}'`);
      } else if (this.filters.campaign) {
        params.push(`campaign_ext_id;eq;'${encodeURIComponent(this.filters.campaign)}'`);
      }

      if (form.id) {
        params.push(`id;eq;'${form.id}'`);
      } else {
        if (this.filters.status.length > 0) {
          const status = this.filters.status.map((value) => value.toUpperCase());
          // params.push(`status;in;'${status}'`);
          params.push(`status;in;'${status.join('\':\'')}'`);
        }

        if (this.filters.to) {
          params.push(`recipient;eq;'${encodeURIComponent(this.filters.to)}'`);
        } else if (form.from) {
          // Cliente nos responde
          params.push('message_origin;eq;\'CLIENT\'');
          params.push(`client_number;eq;'${encodeURIComponent(this.filters.from)}'`);
        }

        if (this.filters.sender) {
          params.push(`sender;eq;'${encodeURIComponent(this.filters.sender)}'`);
        }

        if (this.startDate) {
          params.push(`timestamp;gte;'${moment(this.startDate).toISOString()}'`);
        }

        if (this.endDate) {
          params.push(`timestamp;lte;'${moment(this.endDate).toISOString()}'`);
        }
        params.push(`whatsapp_business.account_id;eq;'${this.$store.state.account.id}'`);
      }

      const orderBy = 'orderBy=timestamp;desc';

      WhatsService.getNewHistory(`query[]=${params.join('&query[]=')}&page=${this.filters.page}&${orderBy}`).then(
        (response) => {
          this.messages = response.data.content;
          this.totalPages = response.data.totalPages;
        },
        (error) => {
          this.content = error;
          this.loading = false;
          this.$toast.show({
            title: 'Erro',
            content: 'Erro interno, por favor tente novamente mais tarde.',
            type: 'danger',
          });
          console.log(JSON.stringify(error.response.data));
        },
      ).finally(() => {
        this.fetching = false;
      });
    },
    exportData(format) {
      this.loading = true;
      console.log(this.filters);
      console.log('FETCH WHATSAPP');

      // this.filters.page = page - 1;
      this.fetched = false;

      // query=x&query=y&page=1

      const accounId = this.$store.state.account.id;
      const form = this.form;

      const params = [];

      if (this.$route.params.id) {
        params.push(`campaign_ext_id;eq;'${encodeURIComponent(this.$route.params.id)}'`);
      } else if (this.filters.campaign) {
        params.push(`campaign_ext_id;eq;'${encodeURIComponent(this.filters.campaign)}'`);
      }

      if (form.id) {
        params.push(`id;eq;'${form.id}'`);
      } else {
        if (this.filters.status.length > 0) {
          const status = this.filters.status.map((value) => value.toUpperCase());
          params.push(`status;in;'${status}'`);
        }

        if (this.filters.to) {
          // Contactmos o cliente
          params.push('message_origin;ne;\'CLIENT\'');
          params.push(`client_number;eq;'${encodeURIComponent(this.filters.to)}'`);
        } else if (form.sender) {
          // Cliente nos responde
          params.push('message_origin;eq;\'CLIENT\'');
          params.push(`client_number;eq;'${encodeURIComponent(this.filters.from)}'`);
        }

        if (this.startDate) {
          params.push(`timestamp;gte;'${moment(this.startDate).toISOString()}'`);
        }

        if (this.endDate) {
          params.push(`timestamp;lte;'${moment(this.endDate).toISOString()}'`);
        }
        params.push(`whatsapp_business.account_id;eq;'${accounId}'`);
      }

      const orderBy = 'orderBy=timestamp;desc';

      webApi.post(`/whatsapp/new/report/generate?query[]=${params.join('&query[]=')}&format=${format}&page=${this.filters.page}&${orderBy}`, {
        from: this.filters.gte,
        to: this.filters.lte,
        format,
      }).then((response) => {
        console.log('Export');
        console.log(response);
        this.loading = false;
        this.$router.push('/whatsapp/jv/reports/exports');
      }, (error) => {
        this.loading = false;
        this.content = error;
        this.$toast.show({
          title: 'Erro',
          content: 'Erro interno, por favor tente novamente mais tarde ou contact o suporte para ajuda.',
          type: 'error',
        });
      });
    },
  },
};
</script>

<style scoped lang="scss">
.btn-search-id {
  height: 35px;
}

.btn-export {
  margin-left: 1px;
  padding: 8px 20px !important;
}

.m-top-10 {
  margin-top: 10px;
}

/* Rebranding */
.rebranding .card.borderless {
  box-shadow: unset !important;
}

.form-group {
  .icon_btn {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid var(--form-border);
    border-left-color: transparent;
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    span {
      color: var(--gray-font-color);
    }
  }
}

.darkmode .form-group .icon_btn {
  background-color: var(--form-input);
  border-color: var(--form-border);
  border-left-color: transparent;
}

.icon-success {
  color: #6eda2c;
}

.icon-failed {
  color: #ff0000;
}

table {
  border-collapse: separate !important;
  border-spacing: 0 10px !important;

  &.busy {
    opacity: 0.55;
  }
}

.table th,
.table thead th {
  border: none;
}

td,
th {
  vertical-align: middle;
}

tr td {
  padding: 18px;
}

td {
  border: 1px solid #e5e5e5;
  border-style: solid none;
}

td:first-child {
  border-left-style: solid;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

td:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}

.text-primary {
  color: var(--clr-yup-purple) !important;
}

.link-out-side {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;

}

.link-message {
  margin-left: 3px;
  font-size: 18px;
}

.no-item-list {
  padding-top: 60px;
  width: 30%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #c7c7c7;
}

.no-item-list .material-symbols-outlined {
  font-size: 90px;
  margin-bottom: 10px;
}
</style>
